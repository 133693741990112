// source: identity/services/users/proto/v1/users.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_api_annotations_pb = require('../../../../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.identity.services.users.proto.v1.CreateUserProfileRequest', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.CreateUserProfileResponse', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.GetUserProfileRequest', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.GetUserProfileResponse', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.IsUniqueUsernameRequest', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.IsUniqueUsernameResponse', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.ListUserProfilesRequest', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.ListUserProfilesResponse', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.RemoveUserRolesRequest', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.RemoveUserRolesResponse', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.Update', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.Update.FieldCase', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.UpdateUserProfileRequest', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.UpdateUserProfileResponse', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.UserProfile', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.UserProfile.Metadata', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.UserProfile.NilBool', null, global);
goog.exportSymbol('proto.identity.services.users.proto.v1.UserProfile.VIPMetadata', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.UserProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.identity.services.users.proto.v1.UserProfile.repeatedFields_, null);
};
goog.inherits(proto.identity.services.users.proto.v1.UserProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.UserProfile.displayName = 'proto.identity.services.users.proto.v1.UserProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.UserProfile.VIPMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.identity.services.users.proto.v1.UserProfile.VIPMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.displayName = 'proto.identity.services.users.proto.v1.UserProfile.VIPMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.UserProfile.Metadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.identity.services.users.proto.v1.UserProfile.Metadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.UserProfile.Metadata.displayName = 'proto.identity.services.users.proto.v1.UserProfile.Metadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.UserProfile.NilBool = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.identity.services.users.proto.v1.UserProfile.NilBool, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.UserProfile.NilBool.displayName = 'proto.identity.services.users.proto.v1.UserProfile.NilBool';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.ListUserProfilesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.identity.services.users.proto.v1.ListUserProfilesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.ListUserProfilesRequest.displayName = 'proto.identity.services.users.proto.v1.ListUserProfilesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.ListUserProfilesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.identity.services.users.proto.v1.ListUserProfilesResponse.repeatedFields_, null);
};
goog.inherits(proto.identity.services.users.proto.v1.ListUserProfilesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.ListUserProfilesResponse.displayName = 'proto.identity.services.users.proto.v1.ListUserProfilesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.GetUserProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.identity.services.users.proto.v1.GetUserProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.GetUserProfileRequest.displayName = 'proto.identity.services.users.proto.v1.GetUserProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.GetUserProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.identity.services.users.proto.v1.GetUserProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.GetUserProfileResponse.displayName = 'proto.identity.services.users.proto.v1.GetUserProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.UpdateUserProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.identity.services.users.proto.v1.UpdateUserProfileRequest.repeatedFields_, null);
};
goog.inherits(proto.identity.services.users.proto.v1.UpdateUserProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.UpdateUserProfileRequest.displayName = 'proto.identity.services.users.proto.v1.UpdateUserProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.Update = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.identity.services.users.proto.v1.Update.oneofGroups_);
};
goog.inherits(proto.identity.services.users.proto.v1.Update, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.Update.displayName = 'proto.identity.services.users.proto.v1.Update';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.UpdateUserProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.identity.services.users.proto.v1.UpdateUserProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.UpdateUserProfileResponse.displayName = 'proto.identity.services.users.proto.v1.UpdateUserProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.CreateUserProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.identity.services.users.proto.v1.CreateUserProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.CreateUserProfileRequest.displayName = 'proto.identity.services.users.proto.v1.CreateUserProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.CreateUserProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.identity.services.users.proto.v1.CreateUserProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.CreateUserProfileResponse.displayName = 'proto.identity.services.users.proto.v1.CreateUserProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.repeatedFields_, null);
};
goog.inherits(proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.displayName = 'proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.repeatedFields_, null);
};
goog.inherits(proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.displayName = 'proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest.displayName = 'proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest.displayName = 'proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse.displayName = 'proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.RemoveUserRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.identity.services.users.proto.v1.RemoveUserRolesRequest.repeatedFields_, null);
};
goog.inherits(proto.identity.services.users.proto.v1.RemoveUserRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.RemoveUserRolesRequest.displayName = 'proto.identity.services.users.proto.v1.RemoveUserRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.RemoveUserRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.identity.services.users.proto.v1.RemoveUserRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.RemoveUserRolesResponse.displayName = 'proto.identity.services.users.proto.v1.RemoveUserRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.identity.services.users.proto.v1.IsUniqueUsernameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.IsUniqueUsernameRequest.displayName = 'proto.identity.services.users.proto.v1.IsUniqueUsernameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.identity.services.users.proto.v1.IsUniqueUsernameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.identity.services.users.proto.v1.IsUniqueUsernameResponse.displayName = 'proto.identity.services.users.proto.v1.IsUniqueUsernameResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.identity.services.users.proto.v1.UserProfile.repeatedFields_ = [6,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.UserProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.UserProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UserProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    vipMetadata: (f = msg.getVipMetadata()) && proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.toObject(includeInstance, f),
    metadataList: jspb.Message.toObjectList(msg.getMetadataList(),
    proto.identity.services.users.proto.v1.UserProfile.Metadata.toObject, includeInstance),
    phone: jspb.Message.getFieldWithDefault(msg, 7, ""),
    pin: jspb.Message.getFieldWithDefault(msg, 8, 0),
    allowPinLogin: (f = msg.getAllowPinLogin()) && proto.identity.services.users.proto.v1.UserProfile.NilBool.toObject(includeInstance, f),
    rolesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    username: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.UserProfile}
 */
proto.identity.services.users.proto.v1.UserProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.UserProfile;
  return proto.identity.services.users.proto.v1.UserProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.UserProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.UserProfile}
 */
proto.identity.services.users.proto.v1.UserProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 5:
      var value = new proto.identity.services.users.proto.v1.UserProfile.VIPMetadata;
      reader.readMessage(value,proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.deserializeBinaryFromReader);
      msg.setVipMetadata(value);
      break;
    case 6:
      var value = new proto.identity.services.users.proto.v1.UserProfile.Metadata;
      reader.readMessage(value,proto.identity.services.users.proto.v1.UserProfile.Metadata.deserializeBinaryFromReader);
      msg.addMetadata(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPin(value);
      break;
    case 9:
      var value = new proto.identity.services.users.proto.v1.UserProfile.NilBool;
      reader.readMessage(value,proto.identity.services.users.proto.v1.UserProfile.NilBool.deserializeBinaryFromReader);
      msg.setAllowPinLogin(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoles(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.UserProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.UserProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UserProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVipMetadata();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.serializeBinaryToWriter
    );
  }
  f = message.getMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.identity.services.users.proto.v1.UserProfile.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPin();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getAllowPinLogin();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.identity.services.users.proto.v1.UserProfile.NilBool.serializeBinaryToWriter
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.UserProfile.VIPMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    identity: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.UserProfile.VIPMetadata}
 */
proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.UserProfile.VIPMetadata;
  return proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.UserProfile.VIPMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.UserProfile.VIPMetadata}
 */
proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.UserProfile.VIPMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentity();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string identity = 1;
 * @return {string}
 */
proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.prototype.getIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile.VIPMetadata} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.prototype.setIdentity = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_code = 2;
 * @return {string}
 */
proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.prototype.getUserCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile.VIPMetadata} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.VIPMetadata.prototype.setUserCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.UserProfile.Metadata.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.UserProfile.Metadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.UserProfile.Metadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UserProfile.Metadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.UserProfile.Metadata}
 */
proto.identity.services.users.proto.v1.UserProfile.Metadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.UserProfile.Metadata;
  return proto.identity.services.users.proto.v1.UserProfile.Metadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.UserProfile.Metadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.UserProfile.Metadata}
 */
proto.identity.services.users.proto.v1.UserProfile.Metadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.UserProfile.Metadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.UserProfile.Metadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.UserProfile.Metadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UserProfile.Metadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.identity.services.users.proto.v1.UserProfile.Metadata.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile.Metadata} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.Metadata.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.identity.services.users.proto.v1.UserProfile.Metadata.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile.Metadata} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.Metadata.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.UserProfile.NilBool.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.UserProfile.NilBool.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.UserProfile.NilBool} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UserProfile.NilBool.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.UserProfile.NilBool}
 */
proto.identity.services.users.proto.v1.UserProfile.NilBool.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.UserProfile.NilBool;
  return proto.identity.services.users.proto.v1.UserProfile.NilBool.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.UserProfile.NilBool} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.UserProfile.NilBool}
 */
proto.identity.services.users.proto.v1.UserProfile.NilBool.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.UserProfile.NilBool.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.UserProfile.NilBool.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.UserProfile.NilBool} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UserProfile.NilBool.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool value = 1;
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.UserProfile.NilBool.prototype.getValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile.NilBool} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.NilBool.prototype.setValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string avatar_url = 4;
 * @return {string}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional VIPMetadata vip_metadata = 5;
 * @return {?proto.identity.services.users.proto.v1.UserProfile.VIPMetadata}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.getVipMetadata = function() {
  return /** @type{?proto.identity.services.users.proto.v1.UserProfile.VIPMetadata} */ (
    jspb.Message.getWrapperField(this, proto.identity.services.users.proto.v1.UserProfile.VIPMetadata, 5));
};


/**
 * @param {?proto.identity.services.users.proto.v1.UserProfile.VIPMetadata|undefined} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
*/
proto.identity.services.users.proto.v1.UserProfile.prototype.setVipMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.clearVipMetadata = function() {
  return this.setVipMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.hasVipMetadata = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated Metadata metadata = 6;
 * @return {!Array<!proto.identity.services.users.proto.v1.UserProfile.Metadata>}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.getMetadataList = function() {
  return /** @type{!Array<!proto.identity.services.users.proto.v1.UserProfile.Metadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.identity.services.users.proto.v1.UserProfile.Metadata, 6));
};


/**
 * @param {!Array<!proto.identity.services.users.proto.v1.UserProfile.Metadata>} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
*/
proto.identity.services.users.proto.v1.UserProfile.prototype.setMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.identity.services.users.proto.v1.UserProfile.Metadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.identity.services.users.proto.v1.UserProfile.Metadata}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.addMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.identity.services.users.proto.v1.UserProfile.Metadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.clearMetadataList = function() {
  return this.setMetadataList([]);
};


/**
 * optional string phone = 7;
 * @return {string}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 pin = 8;
 * @return {number}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.getPin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.setPin = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional NilBool allow_pin_login = 9;
 * @return {?proto.identity.services.users.proto.v1.UserProfile.NilBool}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.getAllowPinLogin = function() {
  return /** @type{?proto.identity.services.users.proto.v1.UserProfile.NilBool} */ (
    jspb.Message.getWrapperField(this, proto.identity.services.users.proto.v1.UserProfile.NilBool, 9));
};


/**
 * @param {?proto.identity.services.users.proto.v1.UserProfile.NilBool|undefined} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
*/
proto.identity.services.users.proto.v1.UserProfile.prototype.setAllowPinLogin = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.clearAllowPinLogin = function() {
  return this.setAllowPinLogin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.hasAllowPinLogin = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated string roles = 10;
 * @return {!Array<string>}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.getRolesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional string username = 11;
 * @return {string}
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.UserProfile} returns this
 */
proto.identity.services.users.proto.v1.UserProfile.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.ListUserProfilesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.ListUserProfilesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.ListUserProfilesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.ListUserProfilesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    drivers: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    sales: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.ListUserProfilesRequest}
 */
proto.identity.services.users.proto.v1.ListUserProfilesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.ListUserProfilesRequest;
  return proto.identity.services.users.proto.v1.ListUserProfilesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.ListUserProfilesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.ListUserProfilesRequest}
 */
proto.identity.services.users.proto.v1.ListUserProfilesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDrivers(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSales(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.ListUserProfilesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.ListUserProfilesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.ListUserProfilesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.ListUserProfilesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDrivers();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSales();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool drivers = 1;
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.ListUserProfilesRequest.prototype.getDrivers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.identity.services.users.proto.v1.ListUserProfilesRequest} returns this
 */
proto.identity.services.users.proto.v1.ListUserProfilesRequest.prototype.setDrivers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool sales = 2;
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.ListUserProfilesRequest.prototype.getSales = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.identity.services.users.proto.v1.ListUserProfilesRequest} returns this
 */
proto.identity.services.users.proto.v1.ListUserProfilesRequest.prototype.setSales = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.identity.services.users.proto.v1.ListUserProfilesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.ListUserProfilesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.ListUserProfilesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.ListUserProfilesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.ListUserProfilesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profilesList: jspb.Message.toObjectList(msg.getProfilesList(),
    proto.identity.services.users.proto.v1.UserProfile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.ListUserProfilesResponse}
 */
proto.identity.services.users.proto.v1.ListUserProfilesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.ListUserProfilesResponse;
  return proto.identity.services.users.proto.v1.ListUserProfilesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.ListUserProfilesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.ListUserProfilesResponse}
 */
proto.identity.services.users.proto.v1.ListUserProfilesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.identity.services.users.proto.v1.UserProfile;
      reader.readMessage(value,proto.identity.services.users.proto.v1.UserProfile.deserializeBinaryFromReader);
      msg.addProfiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.ListUserProfilesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.ListUserProfilesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.ListUserProfilesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.ListUserProfilesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.identity.services.users.proto.v1.UserProfile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserProfile profiles = 1;
 * @return {!Array<!proto.identity.services.users.proto.v1.UserProfile>}
 */
proto.identity.services.users.proto.v1.ListUserProfilesResponse.prototype.getProfilesList = function() {
  return /** @type{!Array<!proto.identity.services.users.proto.v1.UserProfile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.identity.services.users.proto.v1.UserProfile, 1));
};


/**
 * @param {!Array<!proto.identity.services.users.proto.v1.UserProfile>} value
 * @return {!proto.identity.services.users.proto.v1.ListUserProfilesResponse} returns this
*/
proto.identity.services.users.proto.v1.ListUserProfilesResponse.prototype.setProfilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.identity.services.users.proto.v1.UserProfile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.identity.services.users.proto.v1.UserProfile}
 */
proto.identity.services.users.proto.v1.ListUserProfilesResponse.prototype.addProfiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.identity.services.users.proto.v1.UserProfile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.identity.services.users.proto.v1.ListUserProfilesResponse} returns this
 */
proto.identity.services.users.proto.v1.ListUserProfilesResponse.prototype.clearProfilesList = function() {
  return this.setProfilesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.GetUserProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.GetUserProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.GetUserProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.GetUserProfileRequest}
 */
proto.identity.services.users.proto.v1.GetUserProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.GetUserProfileRequest;
  return proto.identity.services.users.proto.v1.GetUserProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.GetUserProfileRequest}
 */
proto.identity.services.users.proto.v1.GetUserProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.GetUserProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.GetUserProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.GetUserProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.identity.services.users.proto.v1.GetUserProfileRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.GetUserProfileRequest} returns this
 */
proto.identity.services.users.proto.v1.GetUserProfileRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.identity.services.users.proto.v1.GetUserProfileRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.GetUserProfileRequest} returns this
 */
proto.identity.services.users.proto.v1.GetUserProfileRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.GetUserProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.GetUserProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.GetUserProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.identity.services.users.proto.v1.UserProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.GetUserProfileResponse}
 */
proto.identity.services.users.proto.v1.GetUserProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.GetUserProfileResponse;
  return proto.identity.services.users.proto.v1.GetUserProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.GetUserProfileResponse}
 */
proto.identity.services.users.proto.v1.GetUserProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.identity.services.users.proto.v1.UserProfile;
      reader.readMessage(value,proto.identity.services.users.proto.v1.UserProfile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.GetUserProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.GetUserProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.GetUserProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.identity.services.users.proto.v1.UserProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserProfile profile = 1;
 * @return {?proto.identity.services.users.proto.v1.UserProfile}
 */
proto.identity.services.users.proto.v1.GetUserProfileResponse.prototype.getProfile = function() {
  return /** @type{?proto.identity.services.users.proto.v1.UserProfile} */ (
    jspb.Message.getWrapperField(this, proto.identity.services.users.proto.v1.UserProfile, 1));
};


/**
 * @param {?proto.identity.services.users.proto.v1.UserProfile|undefined} value
 * @return {!proto.identity.services.users.proto.v1.GetUserProfileResponse} returns this
*/
proto.identity.services.users.proto.v1.GetUserProfileResponse.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.identity.services.users.proto.v1.GetUserProfileResponse} returns this
 */
proto.identity.services.users.proto.v1.GetUserProfileResponse.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.GetUserProfileResponse.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.identity.services.users.proto.v1.UpdateUserProfileRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.UpdateUserProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UpdateUserProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updatesList: jspb.Message.toObjectList(msg.getUpdatesList(),
    proto.identity.services.users.proto.v1.Update.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileRequest}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.UpdateUserProfileRequest;
  return proto.identity.services.users.proto.v1.UpdateUserProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileRequest}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.identity.services.users.proto.v1.Update;
      reader.readMessage(value,proto.identity.services.users.proto.v1.Update.deserializeBinaryFromReader);
      msg.addUpdates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.UpdateUserProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UpdateUserProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.identity.services.users.proto.v1.Update.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileRequest} returns this
 */
proto.identity.services.users.proto.v1.UpdateUserProfileRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Update updates = 2;
 * @return {!Array<!proto.identity.services.users.proto.v1.Update>}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileRequest.prototype.getUpdatesList = function() {
  return /** @type{!Array<!proto.identity.services.users.proto.v1.Update>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.identity.services.users.proto.v1.Update, 2));
};


/**
 * @param {!Array<!proto.identity.services.users.proto.v1.Update>} value
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileRequest} returns this
*/
proto.identity.services.users.proto.v1.UpdateUserProfileRequest.prototype.setUpdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.identity.services.users.proto.v1.Update=} opt_value
 * @param {number=} opt_index
 * @return {!proto.identity.services.users.proto.v1.Update}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileRequest.prototype.addUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.identity.services.users.proto.v1.Update, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileRequest} returns this
 */
proto.identity.services.users.proto.v1.UpdateUserProfileRequest.prototype.clearUpdatesList = function() {
  return this.setUpdatesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.identity.services.users.proto.v1.Update.oneofGroups_ = [[1,2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.identity.services.users.proto.v1.Update.FieldCase = {
  FIELD_NOT_SET: 0,
  NAME: 1,
  PHONE: 2,
  PIN: 3,
  ALLOW_PIN_LOGIN: 4,
  USERNAME: 5,
  ROLE_TO_ADD: 6,
  ROLE_TO_REMOVE: 7
};

/**
 * @return {proto.identity.services.users.proto.v1.Update.FieldCase}
 */
proto.identity.services.users.proto.v1.Update.prototype.getFieldCase = function() {
  return /** @type {proto.identity.services.users.proto.v1.Update.FieldCase} */(jspb.Message.computeOneofCase(this, proto.identity.services.users.proto.v1.Update.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.Update.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.Update.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.Update} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.Update.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pin: jspb.Message.getFieldWithDefault(msg, 3, 0),
    allowPinLogin: (f = msg.getAllowPinLogin()) && proto.identity.services.users.proto.v1.UserProfile.NilBool.toObject(includeInstance, f),
    username: jspb.Message.getFieldWithDefault(msg, 5, ""),
    roleToAdd: jspb.Message.getFieldWithDefault(msg, 6, ""),
    roleToRemove: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.Update}
 */
proto.identity.services.users.proto.v1.Update.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.Update;
  return proto.identity.services.users.proto.v1.Update.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.Update} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.Update}
 */
proto.identity.services.users.proto.v1.Update.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPin(value);
      break;
    case 4:
      var value = new proto.identity.services.users.proto.v1.UserProfile.NilBool;
      reader.readMessage(value,proto.identity.services.users.proto.v1.UserProfile.NilBool.deserializeBinaryFromReader);
      msg.setAllowPinLogin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleToAdd(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleToRemove(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.Update.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.Update.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.Update} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.Update.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAllowPinLogin();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.identity.services.users.proto.v1.UserProfile.NilBool.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.identity.services.users.proto.v1.Update.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.Update} returns this
 */
proto.identity.services.users.proto.v1.Update.prototype.setName = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.identity.services.users.proto.v1.Update.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.identity.services.users.proto.v1.Update} returns this
 */
proto.identity.services.users.proto.v1.Update.prototype.clearName = function() {
  return jspb.Message.setOneofField(this, 1, proto.identity.services.users.proto.v1.Update.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.Update.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.identity.services.users.proto.v1.Update.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.Update} returns this
 */
proto.identity.services.users.proto.v1.Update.prototype.setPhone = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.identity.services.users.proto.v1.Update.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.identity.services.users.proto.v1.Update} returns this
 */
proto.identity.services.users.proto.v1.Update.prototype.clearPhone = function() {
  return jspb.Message.setOneofField(this, 2, proto.identity.services.users.proto.v1.Update.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.Update.prototype.hasPhone = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 pin = 3;
 * @return {number}
 */
proto.identity.services.users.proto.v1.Update.prototype.getPin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.identity.services.users.proto.v1.Update} returns this
 */
proto.identity.services.users.proto.v1.Update.prototype.setPin = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.identity.services.users.proto.v1.Update.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.identity.services.users.proto.v1.Update} returns this
 */
proto.identity.services.users.proto.v1.Update.prototype.clearPin = function() {
  return jspb.Message.setOneofField(this, 3, proto.identity.services.users.proto.v1.Update.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.Update.prototype.hasPin = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UserProfile.NilBool allow_pin_login = 4;
 * @return {?proto.identity.services.users.proto.v1.UserProfile.NilBool}
 */
proto.identity.services.users.proto.v1.Update.prototype.getAllowPinLogin = function() {
  return /** @type{?proto.identity.services.users.proto.v1.UserProfile.NilBool} */ (
    jspb.Message.getWrapperField(this, proto.identity.services.users.proto.v1.UserProfile.NilBool, 4));
};


/**
 * @param {?proto.identity.services.users.proto.v1.UserProfile.NilBool|undefined} value
 * @return {!proto.identity.services.users.proto.v1.Update} returns this
*/
proto.identity.services.users.proto.v1.Update.prototype.setAllowPinLogin = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.identity.services.users.proto.v1.Update.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.identity.services.users.proto.v1.Update} returns this
 */
proto.identity.services.users.proto.v1.Update.prototype.clearAllowPinLogin = function() {
  return this.setAllowPinLogin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.Update.prototype.hasAllowPinLogin = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string username = 5;
 * @return {string}
 */
proto.identity.services.users.proto.v1.Update.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.Update} returns this
 */
proto.identity.services.users.proto.v1.Update.prototype.setUsername = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.identity.services.users.proto.v1.Update.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.identity.services.users.proto.v1.Update} returns this
 */
proto.identity.services.users.proto.v1.Update.prototype.clearUsername = function() {
  return jspb.Message.setOneofField(this, 5, proto.identity.services.users.proto.v1.Update.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.Update.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string role_to_add = 6;
 * @return {string}
 */
proto.identity.services.users.proto.v1.Update.prototype.getRoleToAdd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.Update} returns this
 */
proto.identity.services.users.proto.v1.Update.prototype.setRoleToAdd = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.identity.services.users.proto.v1.Update.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.identity.services.users.proto.v1.Update} returns this
 */
proto.identity.services.users.proto.v1.Update.prototype.clearRoleToAdd = function() {
  return jspb.Message.setOneofField(this, 6, proto.identity.services.users.proto.v1.Update.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.Update.prototype.hasRoleToAdd = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string role_to_remove = 7;
 * @return {string}
 */
proto.identity.services.users.proto.v1.Update.prototype.getRoleToRemove = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.Update} returns this
 */
proto.identity.services.users.proto.v1.Update.prototype.setRoleToRemove = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.identity.services.users.proto.v1.Update.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.identity.services.users.proto.v1.Update} returns this
 */
proto.identity.services.users.proto.v1.Update.prototype.clearRoleToRemove = function() {
  return jspb.Message.setOneofField(this, 7, proto.identity.services.users.proto.v1.Update.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.Update.prototype.hasRoleToRemove = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.UpdateUserProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UpdateUserProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.identity.services.users.proto.v1.UserProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileResponse}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.UpdateUserProfileResponse;
  return proto.identity.services.users.proto.v1.UpdateUserProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileResponse}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.identity.services.users.proto.v1.UserProfile;
      reader.readMessage(value,proto.identity.services.users.proto.v1.UserProfile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.UpdateUserProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UpdateUserProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.identity.services.users.proto.v1.UserProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserProfile profile = 1;
 * @return {?proto.identity.services.users.proto.v1.UserProfile}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileResponse.prototype.getProfile = function() {
  return /** @type{?proto.identity.services.users.proto.v1.UserProfile} */ (
    jspb.Message.getWrapperField(this, proto.identity.services.users.proto.v1.UserProfile, 1));
};


/**
 * @param {?proto.identity.services.users.proto.v1.UserProfile|undefined} value
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileResponse} returns this
*/
proto.identity.services.users.proto.v1.UpdateUserProfileResponse.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileResponse} returns this
 */
proto.identity.services.users.proto.v1.UpdateUserProfileResponse.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileResponse.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.CreateUserProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.CreateUserProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.CreateUserProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.CreateUserProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.identity.services.users.proto.v1.UserProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.CreateUserProfileRequest}
 */
proto.identity.services.users.proto.v1.CreateUserProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.CreateUserProfileRequest;
  return proto.identity.services.users.proto.v1.CreateUserProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.CreateUserProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.CreateUserProfileRequest}
 */
proto.identity.services.users.proto.v1.CreateUserProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.identity.services.users.proto.v1.UserProfile;
      reader.readMessage(value,proto.identity.services.users.proto.v1.UserProfile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.CreateUserProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.CreateUserProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.CreateUserProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.CreateUserProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.identity.services.users.proto.v1.UserProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserProfile profile = 1;
 * @return {?proto.identity.services.users.proto.v1.UserProfile}
 */
proto.identity.services.users.proto.v1.CreateUserProfileRequest.prototype.getProfile = function() {
  return /** @type{?proto.identity.services.users.proto.v1.UserProfile} */ (
    jspb.Message.getWrapperField(this, proto.identity.services.users.proto.v1.UserProfile, 1));
};


/**
 * @param {?proto.identity.services.users.proto.v1.UserProfile|undefined} value
 * @return {!proto.identity.services.users.proto.v1.CreateUserProfileRequest} returns this
*/
proto.identity.services.users.proto.v1.CreateUserProfileRequest.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.identity.services.users.proto.v1.CreateUserProfileRequest} returns this
 */
proto.identity.services.users.proto.v1.CreateUserProfileRequest.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.CreateUserProfileRequest.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.CreateUserProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.CreateUserProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.CreateUserProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.CreateUserProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.identity.services.users.proto.v1.UserProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.CreateUserProfileResponse}
 */
proto.identity.services.users.proto.v1.CreateUserProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.CreateUserProfileResponse;
  return proto.identity.services.users.proto.v1.CreateUserProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.CreateUserProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.CreateUserProfileResponse}
 */
proto.identity.services.users.proto.v1.CreateUserProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.identity.services.users.proto.v1.UserProfile;
      reader.readMessage(value,proto.identity.services.users.proto.v1.UserProfile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.CreateUserProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.CreateUserProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.CreateUserProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.CreateUserProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.identity.services.users.proto.v1.UserProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserProfile profile = 1;
 * @return {?proto.identity.services.users.proto.v1.UserProfile}
 */
proto.identity.services.users.proto.v1.CreateUserProfileResponse.prototype.getProfile = function() {
  return /** @type{?proto.identity.services.users.proto.v1.UserProfile} */ (
    jspb.Message.getWrapperField(this, proto.identity.services.users.proto.v1.UserProfile, 1));
};


/**
 * @param {?proto.identity.services.users.proto.v1.UserProfile|undefined} value
 * @return {!proto.identity.services.users.proto.v1.CreateUserProfileResponse} returns this
*/
proto.identity.services.users.proto.v1.CreateUserProfileResponse.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.identity.services.users.proto.v1.CreateUserProfileResponse} returns this
 */
proto.identity.services.users.proto.v1.CreateUserProfileResponse.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.CreateUserProfileResponse.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metadataList: jspb.Message.toObjectList(msg.getMetadataList(),
    proto.identity.services.users.proto.v1.UserProfile.Metadata.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest;
  return proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.identity.services.users.proto.v1.UserProfile.Metadata;
      reader.readMessage(value,proto.identity.services.users.proto.v1.UserProfile.Metadata.deserializeBinaryFromReader);
      msg.addMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.identity.services.users.proto.v1.UserProfile.Metadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest} returns this
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated UserProfile.Metadata metadata = 2;
 * @return {!Array<!proto.identity.services.users.proto.v1.UserProfile.Metadata>}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.prototype.getMetadataList = function() {
  return /** @type{!Array<!proto.identity.services.users.proto.v1.UserProfile.Metadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.identity.services.users.proto.v1.UserProfile.Metadata, 2));
};


/**
 * @param {!Array<!proto.identity.services.users.proto.v1.UserProfile.Metadata>} value
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest} returns this
*/
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.prototype.setMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.identity.services.users.proto.v1.UserProfile.Metadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.identity.services.users.proto.v1.UserProfile.Metadata}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.prototype.addMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.identity.services.users.proto.v1.UserProfile.Metadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest} returns this
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest.prototype.clearMetadataList = function() {
  return this.setMetadataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadataList: jspb.Message.toObjectList(msg.getMetadataList(),
    proto.identity.services.users.proto.v1.UserProfile.Metadata.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse;
  return proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.identity.services.users.proto.v1.UserProfile.Metadata;
      reader.readMessage(value,proto.identity.services.users.proto.v1.UserProfile.Metadata.deserializeBinaryFromReader);
      msg.addMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.identity.services.users.proto.v1.UserProfile.Metadata.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserProfile.Metadata metadata = 1;
 * @return {!Array<!proto.identity.services.users.proto.v1.UserProfile.Metadata>}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.prototype.getMetadataList = function() {
  return /** @type{!Array<!proto.identity.services.users.proto.v1.UserProfile.Metadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.identity.services.users.proto.v1.UserProfile.Metadata, 1));
};


/**
 * @param {!Array<!proto.identity.services.users.proto.v1.UserProfile.Metadata>} value
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse} returns this
*/
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.prototype.setMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.identity.services.users.proto.v1.UserProfile.Metadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.identity.services.users.proto.v1.UserProfile.Metadata}
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.prototype.addMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.identity.services.users.proto.v1.UserProfile.Metadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse} returns this
 */
proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.prototype.clearMetadataList = function() {
  return this.setMetadataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest}
 */
proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest;
  return proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest}
 */
proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest} returns this
 */
proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest} returns this
 */
proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest}
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest;
  return proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest}
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_code = 2;
 * @return {string}
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest.prototype.getUserCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest} returns this
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest.prototype.setUserCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.identity.services.users.proto.v1.UserProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse}
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse;
  return proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse}
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.identity.services.users.proto.v1.UserProfile;
      reader.readMessage(value,proto.identity.services.users.proto.v1.UserProfile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.identity.services.users.proto.v1.UserProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserProfile profile = 1;
 * @return {?proto.identity.services.users.proto.v1.UserProfile}
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse.prototype.getProfile = function() {
  return /** @type{?proto.identity.services.users.proto.v1.UserProfile} */ (
    jspb.Message.getWrapperField(this, proto.identity.services.users.proto.v1.UserProfile, 1));
};


/**
 * @param {?proto.identity.services.users.proto.v1.UserProfile|undefined} value
 * @return {!proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse} returns this
*/
proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse} returns this
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.identity.services.users.proto.v1.RemoveUserRolesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.RemoveUserRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.RemoveUserRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.RemoveUserRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.RemoveUserRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rolesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.RemoveUserRolesRequest}
 */
proto.identity.services.users.proto.v1.RemoveUserRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.RemoveUserRolesRequest;
  return proto.identity.services.users.proto.v1.RemoveUserRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.RemoveUserRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.RemoveUserRolesRequest}
 */
proto.identity.services.users.proto.v1.RemoveUserRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.RemoveUserRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.RemoveUserRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.RemoveUserRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.RemoveUserRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.identity.services.users.proto.v1.RemoveUserRolesRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.RemoveUserRolesRequest} returns this
 */
proto.identity.services.users.proto.v1.RemoveUserRolesRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string roles = 2;
 * @return {!Array<string>}
 */
proto.identity.services.users.proto.v1.RemoveUserRolesRequest.prototype.getRolesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.identity.services.users.proto.v1.RemoveUserRolesRequest} returns this
 */
proto.identity.services.users.proto.v1.RemoveUserRolesRequest.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.identity.services.users.proto.v1.RemoveUserRolesRequest} returns this
 */
proto.identity.services.users.proto.v1.RemoveUserRolesRequest.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.identity.services.users.proto.v1.RemoveUserRolesRequest} returns this
 */
proto.identity.services.users.proto.v1.RemoveUserRolesRequest.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.RemoveUserRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.RemoveUserRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.RemoveUserRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.RemoveUserRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.identity.services.users.proto.v1.UserProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.RemoveUserRolesResponse}
 */
proto.identity.services.users.proto.v1.RemoveUserRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.RemoveUserRolesResponse;
  return proto.identity.services.users.proto.v1.RemoveUserRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.RemoveUserRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.RemoveUserRolesResponse}
 */
proto.identity.services.users.proto.v1.RemoveUserRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.identity.services.users.proto.v1.UserProfile;
      reader.readMessage(value,proto.identity.services.users.proto.v1.UserProfile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.RemoveUserRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.RemoveUserRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.RemoveUserRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.RemoveUserRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.identity.services.users.proto.v1.UserProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserProfile profile = 1;
 * @return {?proto.identity.services.users.proto.v1.UserProfile}
 */
proto.identity.services.users.proto.v1.RemoveUserRolesResponse.prototype.getProfile = function() {
  return /** @type{?proto.identity.services.users.proto.v1.UserProfile} */ (
    jspb.Message.getWrapperField(this, proto.identity.services.users.proto.v1.UserProfile, 1));
};


/**
 * @param {?proto.identity.services.users.proto.v1.UserProfile|undefined} value
 * @return {!proto.identity.services.users.proto.v1.RemoveUserRolesResponse} returns this
*/
proto.identity.services.users.proto.v1.RemoveUserRolesResponse.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.identity.services.users.proto.v1.RemoveUserRolesResponse} returns this
 */
proto.identity.services.users.proto.v1.RemoveUserRolesResponse.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.RemoveUserRolesResponse.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.IsUniqueUsernameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.IsUniqueUsernameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.IsUniqueUsernameRequest}
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.IsUniqueUsernameRequest;
  return proto.identity.services.users.proto.v1.IsUniqueUsernameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.IsUniqueUsernameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.IsUniqueUsernameRequest}
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.IsUniqueUsernameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.IsUniqueUsernameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.identity.services.users.proto.v1.IsUniqueUsernameRequest} returns this
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.identity.services.users.proto.v1.IsUniqueUsernameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.identity.services.users.proto.v1.IsUniqueUsernameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unique: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.identity.services.users.proto.v1.IsUniqueUsernameResponse}
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.identity.services.users.proto.v1.IsUniqueUsernameResponse;
  return proto.identity.services.users.proto.v1.IsUniqueUsernameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.identity.services.users.proto.v1.IsUniqueUsernameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.identity.services.users.proto.v1.IsUniqueUsernameResponse}
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnique(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.identity.services.users.proto.v1.IsUniqueUsernameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.identity.services.users.proto.v1.IsUniqueUsernameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnique();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool unique = 1;
 * @return {boolean}
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameResponse.prototype.getUnique = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.identity.services.users.proto.v1.IsUniqueUsernameResponse} returns this
 */
proto.identity.services.users.proto.v1.IsUniqueUsernameResponse.prototype.setUnique = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.identity.services.users.proto.v1);
