/**
 * @fileoverview gRPC-Web generated client stub for identity.services.users.proto.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v5.26.1
// source: identity/services/users/proto/v1/users.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_api_annotations_pb = require('../../../../../google/api/annotations_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.identity = {};
proto.identity.services = {};
proto.identity.services.users = {};
proto.identity.services.users.proto = {};
proto.identity.services.users.proto.v1 = require('./users_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.identity.services.users.proto.v1.UsersServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.identity.services.users.proto.v1.UsersServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.identity.services.users.proto.v1.ListUserProfilesRequest,
 *   !proto.identity.services.users.proto.v1.ListUserProfilesResponse>}
 */
const methodDescriptor_UsersService_ListUserProfiles = new grpc.web.MethodDescriptor(
  '/identity.services.users.proto.v1.UsersService/ListUserProfiles',
  grpc.web.MethodType.UNARY,
  proto.identity.services.users.proto.v1.ListUserProfilesRequest,
  proto.identity.services.users.proto.v1.ListUserProfilesResponse,
  /**
   * @param {!proto.identity.services.users.proto.v1.ListUserProfilesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.identity.services.users.proto.v1.ListUserProfilesResponse.deserializeBinary
);


/**
 * @param {!proto.identity.services.users.proto.v1.ListUserProfilesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.identity.services.users.proto.v1.ListUserProfilesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.identity.services.users.proto.v1.ListUserProfilesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.identity.services.users.proto.v1.UsersServiceClient.prototype.listUserProfiles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/ListUserProfiles',
      request,
      metadata || {},
      methodDescriptor_UsersService_ListUserProfiles,
      callback);
};


/**
 * @param {!proto.identity.services.users.proto.v1.ListUserProfilesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.identity.services.users.proto.v1.ListUserProfilesResponse>}
 *     Promise that resolves to the response
 */
proto.identity.services.users.proto.v1.UsersServicePromiseClient.prototype.listUserProfiles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/ListUserProfiles',
      request,
      metadata || {},
      methodDescriptor_UsersService_ListUserProfiles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.identity.services.users.proto.v1.GetUserProfileRequest,
 *   !proto.identity.services.users.proto.v1.GetUserProfileResponse>}
 */
const methodDescriptor_UsersService_GetUserProfile = new grpc.web.MethodDescriptor(
  '/identity.services.users.proto.v1.UsersService/GetUserProfile',
  grpc.web.MethodType.UNARY,
  proto.identity.services.users.proto.v1.GetUserProfileRequest,
  proto.identity.services.users.proto.v1.GetUserProfileResponse,
  /**
   * @param {!proto.identity.services.users.proto.v1.GetUserProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.identity.services.users.proto.v1.GetUserProfileResponse.deserializeBinary
);


/**
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.identity.services.users.proto.v1.GetUserProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.identity.services.users.proto.v1.GetUserProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.identity.services.users.proto.v1.UsersServiceClient.prototype.getUserProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/GetUserProfile',
      request,
      metadata || {},
      methodDescriptor_UsersService_GetUserProfile,
      callback);
};


/**
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.identity.services.users.proto.v1.GetUserProfileResponse>}
 *     Promise that resolves to the response
 */
proto.identity.services.users.proto.v1.UsersServicePromiseClient.prototype.getUserProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/GetUserProfile',
      request,
      metadata || {},
      methodDescriptor_UsersService_GetUserProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.identity.services.users.proto.v1.CreateUserProfileRequest,
 *   !proto.identity.services.users.proto.v1.CreateUserProfileResponse>}
 */
const methodDescriptor_UsersService_CreateUserProfile = new grpc.web.MethodDescriptor(
  '/identity.services.users.proto.v1.UsersService/CreateUserProfile',
  grpc.web.MethodType.UNARY,
  proto.identity.services.users.proto.v1.CreateUserProfileRequest,
  proto.identity.services.users.proto.v1.CreateUserProfileResponse,
  /**
   * @param {!proto.identity.services.users.proto.v1.CreateUserProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.identity.services.users.proto.v1.CreateUserProfileResponse.deserializeBinary
);


/**
 * @param {!proto.identity.services.users.proto.v1.CreateUserProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.identity.services.users.proto.v1.CreateUserProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.identity.services.users.proto.v1.CreateUserProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.identity.services.users.proto.v1.UsersServiceClient.prototype.createUserProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/CreateUserProfile',
      request,
      metadata || {},
      methodDescriptor_UsersService_CreateUserProfile,
      callback);
};


/**
 * @param {!proto.identity.services.users.proto.v1.CreateUserProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.identity.services.users.proto.v1.CreateUserProfileResponse>}
 *     Promise that resolves to the response
 */
proto.identity.services.users.proto.v1.UsersServicePromiseClient.prototype.createUserProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/CreateUserProfile',
      request,
      metadata || {},
      methodDescriptor_UsersService_CreateUserProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.identity.services.users.proto.v1.UpdateUserProfileRequest,
 *   !proto.identity.services.users.proto.v1.UpdateUserProfileResponse>}
 */
const methodDescriptor_UsersService_UpdateUserProfile = new grpc.web.MethodDescriptor(
  '/identity.services.users.proto.v1.UsersService/UpdateUserProfile',
  grpc.web.MethodType.UNARY,
  proto.identity.services.users.proto.v1.UpdateUserProfileRequest,
  proto.identity.services.users.proto.v1.UpdateUserProfileResponse,
  /**
   * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.identity.services.users.proto.v1.UpdateUserProfileResponse.deserializeBinary
);


/**
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.identity.services.users.proto.v1.UpdateUserProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.identity.services.users.proto.v1.UpdateUserProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.identity.services.users.proto.v1.UsersServiceClient.prototype.updateUserProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/UpdateUserProfile',
      request,
      metadata || {},
      methodDescriptor_UsersService_UpdateUserProfile,
      callback);
};


/**
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.identity.services.users.proto.v1.UpdateUserProfileResponse>}
 *     Promise that resolves to the response
 */
proto.identity.services.users.proto.v1.UsersServicePromiseClient.prototype.updateUserProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/UpdateUserProfile',
      request,
      metadata || {},
      methodDescriptor_UsersService_UpdateUserProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest,
 *   !proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse>}
 */
const methodDescriptor_UsersService_UpdateUserProfileMetadata = new grpc.web.MethodDescriptor(
  '/identity.services.users.proto.v1.UsersService/UpdateUserProfileMetadata',
  grpc.web.MethodType.UNARY,
  proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest,
  proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse,
  /**
   * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse.deserializeBinary
);


/**
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.identity.services.users.proto.v1.UsersServiceClient.prototype.updateUserProfileMetadata =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/UpdateUserProfileMetadata',
      request,
      metadata || {},
      methodDescriptor_UsersService_UpdateUserProfileMetadata,
      callback);
};


/**
 * @param {!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.identity.services.users.proto.v1.UpdateUserProfileMetadataResponse>}
 *     Promise that resolves to the response
 */
proto.identity.services.users.proto.v1.UsersServicePromiseClient.prototype.updateUserProfileMetadata =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/UpdateUserProfileMetadata',
      request,
      metadata || {},
      methodDescriptor_UsersService_UpdateUserProfileMetadata);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UsersService_DeleteUserProfileMetadata = new grpc.web.MethodDescriptor(
  '/identity.services.users.proto.v1.UsersService/DeleteUserProfileMetadata',
  grpc.web.MethodType.UNARY,
  proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.identity.services.users.proto.v1.UsersServiceClient.prototype.deleteUserProfileMetadata =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/DeleteUserProfileMetadata',
      request,
      metadata || {},
      methodDescriptor_UsersService_DeleteUserProfileMetadata,
      callback);
};


/**
 * @param {!proto.identity.services.users.proto.v1.DeleteUserProfileMetadataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.identity.services.users.proto.v1.UsersServicePromiseClient.prototype.deleteUserProfileMetadata =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/DeleteUserProfileMetadata',
      request,
      metadata || {},
      methodDescriptor_UsersService_DeleteUserProfileMetadata);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest,
 *   !proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse>}
 */
const methodDescriptor_UsersService_GetUserProfileByCode = new grpc.web.MethodDescriptor(
  '/identity.services.users.proto.v1.UsersService/GetUserProfileByCode',
  grpc.web.MethodType.UNARY,
  proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest,
  proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse,
  /**
   * @param {!proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse.deserializeBinary
);


/**
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.identity.services.users.proto.v1.UsersServiceClient.prototype.getUserProfileByCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/GetUserProfileByCode',
      request,
      metadata || {},
      methodDescriptor_UsersService_GetUserProfileByCode,
      callback);
};


/**
 * @param {!proto.identity.services.users.proto.v1.GetUserProfileByCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.identity.services.users.proto.v1.GetUserProfileByCodeResponse>}
 *     Promise that resolves to the response
 */
proto.identity.services.users.proto.v1.UsersServicePromiseClient.prototype.getUserProfileByCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/GetUserProfileByCode',
      request,
      metadata || {},
      methodDescriptor_UsersService_GetUserProfileByCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.identity.services.users.proto.v1.RemoveUserRolesRequest,
 *   !proto.identity.services.users.proto.v1.RemoveUserRolesResponse>}
 */
const methodDescriptor_UsersService_RemoveUserRoles = new grpc.web.MethodDescriptor(
  '/identity.services.users.proto.v1.UsersService/RemoveUserRoles',
  grpc.web.MethodType.UNARY,
  proto.identity.services.users.proto.v1.RemoveUserRolesRequest,
  proto.identity.services.users.proto.v1.RemoveUserRolesResponse,
  /**
   * @param {!proto.identity.services.users.proto.v1.RemoveUserRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.identity.services.users.proto.v1.RemoveUserRolesResponse.deserializeBinary
);


/**
 * @param {!proto.identity.services.users.proto.v1.RemoveUserRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.identity.services.users.proto.v1.RemoveUserRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.identity.services.users.proto.v1.RemoveUserRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.identity.services.users.proto.v1.UsersServiceClient.prototype.removeUserRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/RemoveUserRoles',
      request,
      metadata || {},
      methodDescriptor_UsersService_RemoveUserRoles,
      callback);
};


/**
 * @param {!proto.identity.services.users.proto.v1.RemoveUserRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.identity.services.users.proto.v1.RemoveUserRolesResponse>}
 *     Promise that resolves to the response
 */
proto.identity.services.users.proto.v1.UsersServicePromiseClient.prototype.removeUserRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/RemoveUserRoles',
      request,
      metadata || {},
      methodDescriptor_UsersService_RemoveUserRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.identity.services.users.proto.v1.IsUniqueUsernameRequest,
 *   !proto.identity.services.users.proto.v1.IsUniqueUsernameResponse>}
 */
const methodDescriptor_UsersService_IsUniqueUsername = new grpc.web.MethodDescriptor(
  '/identity.services.users.proto.v1.UsersService/IsUniqueUsername',
  grpc.web.MethodType.UNARY,
  proto.identity.services.users.proto.v1.IsUniqueUsernameRequest,
  proto.identity.services.users.proto.v1.IsUniqueUsernameResponse,
  /**
   * @param {!proto.identity.services.users.proto.v1.IsUniqueUsernameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.identity.services.users.proto.v1.IsUniqueUsernameResponse.deserializeBinary
);


/**
 * @param {!proto.identity.services.users.proto.v1.IsUniqueUsernameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.identity.services.users.proto.v1.IsUniqueUsernameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.identity.services.users.proto.v1.IsUniqueUsernameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.identity.services.users.proto.v1.UsersServiceClient.prototype.isUniqueUsername =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/IsUniqueUsername',
      request,
      metadata || {},
      methodDescriptor_UsersService_IsUniqueUsername,
      callback);
};


/**
 * @param {!proto.identity.services.users.proto.v1.IsUniqueUsernameRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.identity.services.users.proto.v1.IsUniqueUsernameResponse>}
 *     Promise that resolves to the response
 */
proto.identity.services.users.proto.v1.UsersServicePromiseClient.prototype.isUniqueUsername =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/identity.services.users.proto.v1.UsersService/IsUniqueUsername',
      request,
      metadata || {},
      methodDescriptor_UsersService_IsUniqueUsername);
};


module.exports = proto.identity.services.users.proto.v1;

