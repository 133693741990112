export const validEmail = (email: string): boolean => {
  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(email);
};

export const generateFakeEmail = (name: string): string => {
  const random4CharacterString = Math.random().toString(36).substring(2, 6);
  const sanitizedName = name.replace(/[\s-]/g, "").toLocaleLowerCase();
  return `${sanitizedName}-${random4CharacterString}@fake.com`;
};

// Check if email domain is fake.com
export const isFakeEmail = (email: string): boolean => {
  const emailDomain = email.split("@")[1];
  return emailDomain === "fake.com";
};
