import axios from "axios";
import { useQuery } from "react-query";
import { Retailerv2, RetailerAudit } from "../../core/types/retailer";
import { generateFakeEmail } from "../util/email";
import { baseURL } from "../util/url";

const fetchRetailerAudits = async (): Promise<RetailerAudit[]> => {
	const { data } = await axios({
		baseURL: baseURL("retailers"),
		method: "get",
		url: `/retailers/audit`,
	});

	return data as RetailerAudit[];
};

const triggerRetailerAudits = async (): Promise<any> => {
	const { data } = await axios({
		baseURL: baseURL("retailers"),
		method: "post",
		url: `/retailers/audit/trigger`,
	});

	return data as RetailerAudit[];
};

const fetchRetailers = async (
	options?: any,
): Promise<Map<string, Retailerv2>> => {
	return axios({
		baseURL: baseURL("retailers"),
		method: "get",
		url: `/retailers?source=bq&noWarehouse=true`,
		params: options,
	}).then((response) => {
		const retailers = new Map<string, Retailerv2>();
		response.data.retailers.forEach((r: Retailerv2) => {
			if (r.metadata && r.metadata.externalValue) {
				r.id = r.metadata.externalValue;
				retailers.set(r.metadata.externalValue, r);
			} else {
				retailers.set(r.id.toString(), r);
			}
		});
		return retailers;
	});
};

const fetchRetailer = async (id?: string): Promise<Retailerv2> => {
	if (!id) {
		return Promise.reject("No retailer id provided");
	}
	return axios({
		baseURL: baseURL("retailers"),
		method: "get",
		url: `/retailers/${id}`,
	}).then((response) => {
		return response.data.retailer as Retailerv2;
	});
};

export const fetchUpdateRetailer = async (
	id: string,
	update: {
		accountOrderType?: string;
		config?: {
			disableNeedsCount: boolean;
		};
	},
): Promise<any> => {
	return axios({
		baseURL: baseURL("retailers"),
		method: "put",
		url: `/retailers/${id}`,
		data: update,
	});
};

export function useAuditRetailers() {
	return useQuery(["audit-retailers"], () => {
		return fetchRetailerAudits();
	});
}

export function useTriggerAudit() {
	return useQuery(
		["trigger-retailer-audit"],
		() => {
			return triggerRetailerAudits();
		},
		{
			enabled: false,
		},
	);
}

export function useRetailers(options?: any) {
	return useQuery(
		["retailers"],
		() => {
			return fetchRetailers(options);
		},
		{
			cacheTime: 1000 * 60 * 60 * 24,
		},
	);
}

export function useRetailer(id?: string) {
	return useQuery(["retailer", id], () => {
		return fetchRetailer(id);
	});
}

export const addRetailerUser = async ({
	retailerId,
	name,
	email,
	phone,
	sendEmail,
	sendSms,
}: {
	retailerId: string;
	name: string;
	email: string;
	phone: string;
	sendEmail: boolean;
	sendSms: boolean;
}): Promise<any> => {
	if (!email) {
		email = generateFakeEmail(name);
	}
	return axios({
		method: "post",
		url: `/retailers/${retailerId}/users`,
		data: {
			name,
			email,
			phone,
			sendEmail,
			sendSms,
		},
	}).then((response) => {
		return response.data;
	});
};

export const updateRetailerUser = async ({
	retailerId,
	userId,
	phone,
	sendEmail,
	sendSms,
}: {
	retailerId: string;
	userId: string;
	phone: string;
	sendEmail: boolean;
	sendSms: boolean;
}): Promise<any> => {
	return axios({
		method: "put",
		url: `/retailers/${retailerId}/users/${userId}`,
		data: {
			phone,
			sendEmail,
			sendSms,
		},
	}).then((response) => {
		return response.data;
	});
};

export const deleteRetailerUser = async ({
	retailerId,
	userId,
}: {
	retailerId: string;
	userId: string;
}): Promise<any> => {
	const { data } = await axios({
		method: "delete",
		url: `/retailers/${retailerId}/users/${userId}`,
	});

	return data;
};
