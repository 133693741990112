import { initializeApp } from "@firebase/app";
import { getAuth as gAuth, browserLocalPersistence } from "@firebase/auth";

const app = initializeApp({
  apiKey: "AIzaSyAjecgqqW7LFuuzaXeKKgTetINQJ0YpYLI",
  authDomain: "project-chaski.firebaseapp.com",
  projectId: "project-chaski",
});

export async function getAuth() {
  const auth = gAuth(app);
  auth.setPersistence(browserLocalPersistence);

  return auth;
}
