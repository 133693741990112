import { useQuery } from "react-query";

import { IdTokenResult, browserLocalPersistence } from "@firebase/auth";

import { UserInfo } from "../types/userInfo";
import { getAuth } from "./app";
import axios from "axios";

const fetchUserInfo = async (): Promise<UserInfo> => {
  let tokenResult: IdTokenResult | undefined;
  const auth = await getAuth();
  await auth.setPersistence(browserLocalPersistence);
  tokenResult = await auth.currentUser?.getIdTokenResult();
  const refreshToken = auth.currentUser?.refreshToken;
  const token = tokenResult?.token || "";

  const claims: string[] = [];
  for (const [key, value] of Object.entries(tokenResult?.claims || [])) {
    claims.push(key);
    console.log(`${key}: ${value}`);
  }

  let tenantID = "";
  if (tokenResult && tokenResult.claims["firebase"]) {
    const claims = tokenResult.claims["firebase"] as any;
    tenantID = claims["tenant_id"] || "";
  }

  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const userInfo: UserInfo = {
    email: tokenResult?.claims["email"],
    id: token,
    name: tokenResult?.claims["name"],
    avatar: tokenResult?.claims["picture"],
    permissions: claims,
    token: token,
    userID: tokenResult?.claims["user_id"],
    tenantID: tenantID,
    refreshToken: refreshToken?.toString() || "",
  };

  return userInfo;
};

export function useUserInfo() {
  return useQuery(["user-info"], () => fetchUserInfo(), {
    suspense: true,
    cacheTime: 0,
  });
}
