import {
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Canvas from "../../components/Canvas";
import { ForceGraph } from "./helpers/force";
import { Retailerv2 } from "../../../core/types/retailer";
import { useGalileo } from "../../hooks/useScenario";
import { Graph } from "../../../core/types/graph";
import { useAuth } from "../../../auth/contexts/AuthProvider";

type key = {
	baseline: string;
	dynamic: string;
};

const customerSimulationMap = new Map<string, key>([
	[
		"kentucky-eagle-15dzk",
		{
			baseline: "ke_baseline_6months",
			dynamic: "ke_dynamic_6months",
		},
	],
	[
		"mitchell-distrib-q7uwg",
		{
			baseline: "a996a0ac-cb51-467c-b36d-003ed238e6e2",
			dynamic: "209ee631-45bb-4183-947c-6bd967f6c1aa",
		},
	],
]);

type RouteCorrelationProps = {
	retailers?: Map<string, Retailerv2>;
};
const RouteCorrelation = ({ retailers }: RouteCorrelationProps) => {
	const stageRef = useRef<HTMLDivElement>(null);
	const { getTenantId } = useAuth();
	const tenantId = getTenantId();
	const { baseline, dynamic } = customerSimulationMap.get(
		tenantId || "kentucky-eagle-15dzk",
	) || {
		baseline: "ke_baseline_6months",
		dynamic: "ke_dynamic_6months",
	};
	const { data: graphDynamic } = useGalileo(dynamic);
	const { data: graphBaseline } = useGalileo(baseline);
	const [dims] = useState({ width: 600, height: 600 });

	const [graph, setGraph] = useState<Graph | undefined>(undefined);
	const [useFullwidth, setUseFullwidth] = useState(false);

	useEffect(() => {
		setGraph(processGraph(graphBaseline, retailers));
	}, [retailers, graphBaseline]);

	const render = (context: CanvasRenderingContext2D) => {
		if (!graph || graph.nodes.length === 0) return;
		ForceGraph(graph, {
			context,
			nodeId: (d) => d.id,
			nodeGroup: (d) => d.group && d.group.toLowerCase(),
			nodeGroups: [
				...graph.nodes
					.reduce((acc: Set<string>, node: any) => {
						if (node.group && acc.has(node.group.toLowerCase()) === false) {
							acc.add(node.group.toLowerCase());
						}
						return acc;
					}, new Set<string>())
					.values(),
			],
			nodeTitle: (d) => `${d.id} (${d.group})`,
			linkStroke: `rgb(100,100,100)`,
			linkStrokeOpacity: (d) => d.value * 0.05,
			width: dims.width,
			height: dims.height,
		});
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		switch ((event.target as HTMLInputElement).value) {
			case "baseline":
				setGraph(processGraph(graphBaseline, retailers));
				break;
			case "dynamic":
				setGraph(processGraph(graphDynamic, retailers));
				break;
		}
	};

	const handleFullwidthSelection = (e: React.ChangeEvent<HTMLInputElement>) =>
		setUseFullwidth(e.target.checked);

	if (!graph || !retailers) {
		return null;
	}

	return (
		<Card ref={stageRef}>
			<CardHeader
				title="Route Correlation"
				subheader={`This network diagram is meant to visualize the network effect of routes stops that tend to be grouped together.`}
			/>
			<CardContent>
				<FormControl>
					<FormLabel>Scenario</FormLabel>
					<RadioGroup
						name="controlled-radio-buttons-group"
						defaultValue="baseline"
						onChange={handleChange}
						row
					>
						<FormControlLabel
							value="baseline"
							control={<Radio />}
							label="Baseline"
						/>
						<FormControlLabel
							value="dynamic"
							control={<Radio />}
							label="Dynamic"
						/>
					</RadioGroup>
				</FormControl>
				<FormControl>
					<FormLabel>Full width?</FormLabel>
					<Checkbox
						checked={useFullwidth}
						onChange={handleFullwidthSelection}
					/>
				</FormControl>
			</CardContent>
			<CardContent>
				<Canvas
					render={render}
					graph={graph}
					props={dims}
					fullWidth={useFullwidth}
				/>
			</CardContent>
		</Card>
	);
};

const processGraph = (
	g: Graph | undefined,
	r?: Map<string, Retailerv2>,
): Graph | undefined => {
	if (!g) return g;
	if (!r) return g;
	const nodes = [
		{},
		...g.nodes.map((node: any) => {
			if (!r) {
				return node;
			}
			const retailerId = node.id + "";
			const retailerIdPadded = retailerId.padStart(5, "0");
			const retailer = r?.get(retailerIdPadded);
			if (!retailer) {
				console.log("No retailer found for", retailerIdPadded, r);
				return node;
			}

			const n = {
				id: node.id,
				group: retailer.location.city,
				name: retailer.name,
			};

			console.log(n);

			return n;
		}),
	];

	const links = g.links.filter((link) => link.value && link.value !== 0);

	return {
		...g,
		links,
		nodes,
	};
};

export default RouteCorrelation;
