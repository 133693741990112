// import axios from "axios";
import { useQuery } from "react-query";
import { Notification } from "../types/notification";

const fetchNotifications = async (): Promise<Notification[]> => {
  // return a fake notification for now
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([]);
    }, 1000);
  });

  // const { data } = await axios.get("/notifications");
  // return data;
};

export function useNotifications() {
  return useQuery(["notifications"], () => fetchNotifications(), {
    suspense: false,
    cacheTime: 0,
  });
}
