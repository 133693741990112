// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/disjoint-force-directed-graph

import * as d3 from "d3";
import { Graph } from "../../../../core/types/graph";

type params = {
  context?: CanvasRenderingContext2D;
  nodeId?: (d: any) => string;
  nodeGroup?: (d: any) => string;
  nodeGroups?: string[];
  nodeTitle?: (d: any, i: number) => string;
  nodeFill?: string;
  nodeStroke?: string;
  nodeStrokeWidth?: number;
  nodeStrokeOpacity?: number;
  nodeRadius?: number;
  nodeStrength?: number;
  linkSource?: (d: any) => string;
  linkTarget?: (d: any) => string;
  linkStroke?: string | ((d: any) => string);
  linkStrokeOpacity?: (d: any) => number;
  linkStrokeWidth?: number;
  linkStrokeLinecap?: string;
  linkStrength?: number;
  colors?: readonly string[];
  width?: number;
  height?: number;
  invalidation?: Promise<any>;
};

export function ForceGraph(
  {
    nodes, // an iterable of node objects (typically [{id}, …])
    links, // an iterable of link objects (typically [{source, target}, …])
  }: Graph,
  {
    context,
    nodeId = (d) => d.id, // given d in nodes, returns a unique identifier (string)
    nodeGroup, // given d in nodes, returns an (ordinal) value for color
    nodeGroups, // an array of ordinal values representing the node groups
    nodeFill = "currentColor", // node stroke fill (if not using a group color encoding)
    nodeStroke = "#fff", // node stroke color
    nodeStrokeWidth = 1.5, // node stroke width, in pixels
    nodeStrokeOpacity = 1, // node stroke opacity
    nodeRadius = 5, // node radius, in pixels
    nodeStrength,
    linkSource = ({ source }) => source, // given d in links, returns a node identifier string
    linkTarget = ({ target }) => target, // given d in links, returns a node identifier string
    linkStroke = "#999", // link stroke color
    linkStrokeOpacity = () => 0.1, // link stroke opacity
    linkStrokeWidth = 1.0, // given d in links, returns a stroke width in pixels
    linkStrokeLinecap = "round", // link stroke linecap
    linkStrength,
    colors = d3.schemeTableau10, // an array of color strings, for the node groups
    width = 640, // outer width, in pixels
    height = 400, // outer height, in pixels
    invalidation, // when this promise resolves, stop the simulation,
  }: params = {}
) {
  // Compute values.
  const N = d3.map(nodes, nodeId).map(intern);
  const LS = d3.map(links, linkSource).map(intern);
  const LT = d3.map(links, linkTarget).map(intern);
  const G = nodeGroup == null ? null : d3.map(nodes, nodeGroup).map(intern);
  const W =
    typeof linkStrokeWidth !== "function"
      ? null
      : d3.map(links, linkStrokeWidth);
  const L = typeof linkStroke !== "function" ? null : d3.map(links, linkStroke);

  // Replace the input nodes and links with mutable objects for the simulation.
  nodes = d3.map(nodes, (n, i) => ({ ...n, id: N[i] }));
  links = d3.map(links, (l, i) => ({ ...l, source: LS[i], target: LT[i] }));

  // Compute default domains.
  if (G && nodeGroups === undefined) nodeGroups = d3.sort(G);

  // Construct the scales.
  const color =
    nodeGroup == null ? null : d3.scaleOrdinal(nodeGroups || [], colors);

  // Construct the forces.
  const forceNode = d3.forceManyBody();
  const forceLink = d3.forceLink(links).id(({ index: i }) => i && N[i]);
  if (nodeStrength !== undefined) forceNode.strength(nodeStrength);
  if (linkStrength !== undefined) forceLink.strength(linkStrength);

  const simulation = d3
    .forceSimulation(nodes as any)
    .force("link", forceLink)
    .force("charge", forceNode)
    .force("center", d3.forceCenter(width / 2, height / 2))
    .force("collide", d3.forceCollide(nodeRadius + 1))
    .on("tick", ticked)
    .on("end", () => {
      d3.timer(() => {
        ticked();
      }, 5);
    });

  if (!context) return;

  let [tx, ty, k] = [0, 0, 1];
  let [mx, my] = [0, 0];
  let hoverText = "";

  function ticked() {
    if (context == null) return;
    context.clearRect(0, 0, width, height);

    tx = tx + 0; // keep a strong reference to tx
    ty = ty + 0; // keep a strong reference to ty
    k = k + 0; // keep a strong reference to k

    context.save();
    context.translate(tx, ty);
    context.scale(k, k);
    for (const [i, link] of links.entries()) {
      context.beginPath();
      drawLink(link);
      context.strokeStyle = L
        ? (L[i] as string | CanvasGradient | CanvasPattern)
        : typeof linkStroke === "function"
        ? linkStroke(link)
        : linkStroke;
      context.globalAlpha = linkStrokeOpacity(link);
      context.lineWidth = W ? (W[i] as number) : linkStrokeWidth;
      context.stroke();
    }
    context.restore();

    context.save();
    context.translate(tx, ty);
    context.scale(k, k);
    context.strokeStyle = nodeStroke;
    context.globalAlpha = nodeStrokeOpacity;
    for (const [i, node] of nodes.entries()) {
      context.beginPath();
      drawNode(node);
      context.fillStyle = G && color ? color(G[i]) : nodeFill;
      context.strokeStyle = nodeStroke;
      if (i % 10 === 0) {
        drawText(`${node.group}, `, node.x, node.y - 15);
        drawText(`${node.name}, `, node.x, node.y - 25);
        drawText(`${node.id}, `, node.x, node.y - 35);
      }
      context.fill();
      context.stroke();
    }
    context.restore();

    context.save();
    context.translate(tx, ty);
    context.scale(k, k);
    context.beginPath();
    drawHover(mx, my, k);
    context.fill();
    context.restore();
  }

  function drawLink(d: any) {
    if (context == null) return;
    context.moveTo(d.source.x, d.source.y);
    context.lineTo(d.target.x, d.target.y);
  }

  function drawNode(d: any) {
    if (context == null) return;
    context.moveTo(d.x + nodeRadius, d.y);
    context.arc(d.x, d.y, nodeRadius, 0, 2 * Math.PI);
  }

  function drawText(text: string, x: number, y: number) {
    if (context == null) return;
    context.font = "12px sans-serif";
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, x, y);
  }

  function drawHover(x: number, y: number, k: number) {
    if (context == null) return;
    context.moveTo(x, y);
    context.font = `${12 / k}px sans-serif`;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillStyle = "black";
    context.fillText(hoverText, mx, my);
  }

  if (invalidation != null) invalidation.then(() => simulation.stop());

  function intern(value: any) {
    return value !== null && typeof value === "object"
      ? value.valueOf()
      : value;
  }

  function drag(simulation: d3.Simulation<any, any>) {
    function dragstarted(event: d3.D3DragEvent<any, any, any>) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event: d3.D3DragEvent<any, any, any>) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event: d3.D3DragEvent<any, any, any>) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    function dragsubject(event: d3.D3DragEvent<any, any, any>) {
      return simulation.find(
        event.sourceEvent.offsetX,
        event.sourceEvent.offsetY,
        5
      );
    }

    return d3
      .drag<HTMLCanvasElement, any>()
      .subject(dragsubject)
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  }

  function zoom(simulation: d3.Simulation<any, any>) {
    return d3
      .zoom<HTMLCanvasElement, any>()
      .scaleExtent([1 / 8, 10])
      .on("zoom", zoomed);

    function zoomed(event: d3.D3ZoomEvent<HTMLCanvasElement, any>) {
      if (context == null) return;
      const { transform } = event;

      tx = transform.x;
      ty = transform.y;
      k = transform.k;
    }
  }

  function move(simulation: d3.Simulation<any, any>) {
    function mouseover(
      selection: d3.Selection<HTMLCanvasElement, any, any, any>
    ) {
      return selection.on("mousemove", (event: any) => {
        const [x, y] = d3.pointer(event);
        [mx, my] = [(x - tx) / k, (y - ty) / k];
        const subject = simulation.find(mx, my, 5);

        if (subject) {
          hoverText = `${subject.group}, ${subject.name} (${subject.id})`;
        } else {
          hoverText = "";
        }
      });
    }

    return mouseover;
  }

  return Object.assign(
    d3
      .select(context.canvas)
      .call(drag(simulation))
      .call(zoom(simulation))
      .call(move(simulation))
      .node() || {},
    { scales: { color } }
  );
}
