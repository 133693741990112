// baseUrl takes the domain name (i.e. deliveries) and returns the base URL for the API (i.e. https://deliveries.stage.api.greater.engineering).
export const baseURL = (domain: string): string => {
	const api = process.env.REACT_APP_API_SERVER;
	if (api === undefined) {
		console.error(new Error("REACT_APP_API_SERVER is undefined"));
		return "";
	}

	const [protocol, host] = api.split("://");
	if (host === undefined) {
		console.error(new Error(`REACT_APP_API_SERVER is invalid: ${api}`));
		return api;
	}

	return `${protocol}://${domain}.${host}`;
};

export const grpcURL = (domain: string): string => {
	const api = process.env.REACT_APP_API_SERVER;
	if (api === undefined) {
		console.error(new Error("REACT_APP_API_SERVER is undefined"));
		return "";
	}

	const [, host] = api.split("://");
	if (host === undefined) {
		console.error(new Error(`REACT_APP_API_SERVER is invalid: ${api}`));
		return api;
	}

	return `https://${domain}.${host}:443`;
};
