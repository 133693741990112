import { Grid } from "@mui/material";

import RouteCorrelation from "../../../widgets/routes/RouteCorrelation";
import { useRetailers } from "../../../hooks/useRetailers";
import AppBar from "../../../components/AppBar";
import Toolbar from "../../../components/Toolbar";
import RecentNotifications from "../../../components/RecentNotifications";

const RouteCorrelationPage = () => {
	const { data: retailers } = useRetailers();
	return (
		<>
			<AppBar>
				<Toolbar breadcrumbs={[{ name: "Explore Routing", link: "/explore" }]}>
					<RecentNotifications />
				</Toolbar>
			</AppBar>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<RouteCorrelation retailers={retailers} />
				</Grid>
			</Grid>
		</>
	);
};

export default RouteCorrelationPage;
