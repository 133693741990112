import Box, { BoxProps } from "@mui/material/Box";

type LogoProps = {
  colored?: boolean;
  size?: number;
  compact?: boolean;
} & BoxProps;

const LogoStyle = {
  primary: {
    fontSize: "1.7rem",
  },
  secondary: {
    fontSize: ".75rem",
    fontWeight: "bold",
    lineHeight: ".5rem",
  },
};

const Logo = ({
  compact = false,
  colored = false,
  size = 80,
  ...boxProps
}: LogoProps) => {
  return (
    <Box
      {...boxProps}
      sx={{
        display: "flex",
        flexDirection: compact ? "column" : "row",
        alignItems: "center",
        paddingLeft: "1rem",
      }}
    >
      <img src="/img/chaski.png" alt="logo" height={size} width={size} />
      <Box sx={{ display: compact ? "none" : "flex", flexDirection: "column" }}>
        <Box sx={LogoStyle.primary}>CHASKI</Box>
        <Box sx={LogoStyle.secondary}>greater industries</Box>
      </Box>
    </Box>
  );
};

export default Logo;
