import axios from "axios";
import { useQuery } from "react-query";
import { UserProfile, Metadata } from "../../core/types/profile";
import { CustomerV2 } from "../types/customer";
import { Roles } from "../../auth/types/role";
import { baseURL, grpcURL } from "../util/url";
import {
	UpdateUserProfileRequest,
	UpdateUserProfileResponse,
	UsersServiceClient,
} from "../../proto/identity/services/users/proto/v1/users_grpc_web_pb";
import { getMD } from "./common";

export const updateUserProfile = async (
	request: UpdateUserProfileRequest,
): Promise<UpdateUserProfileResponse> => {
	return new Promise<UpdateUserProfileResponse>((resolve, reject) => {
		const client = new UsersServiceClient(grpcURL("users"), {}, {});
		client.updateUserProfile(request, getMD(), (err, response) => {
			if (err) {
				reject(err);
			} else if (!response) {
				reject(new Error("No response"));
			} else {
				resolve(response);
			}
		});
	});
};

const fetchUserProfile = async (userId: string): Promise<UserProfile> => {
	return axios({
		baseURL: baseURL("users"),
		method: "get",
		url: `/users/${userId}/profile`,
	}).then((response) => {
		if (!response.data.profile) {
			return null;
		}

		return response.data.profile;
	});
};

export function useUserProfile(userId?: string) {
	return useQuery(
		["userProfile", userId],
		() => {
			if (userId !== undefined) {
				return fetchUserProfile(userId);
			}
		},
		{
			enabled: userId !== undefined,
			cacheTime: 0,
		},
	);
}

const fetchUsers = async (): Promise<UserProfile[]> => {
	return axios({
		baseURL: baseURL("auth"),
		method: "get",
		url: "/auth/users",
		params: {
			tenantId: "kentucky-eagle-15dzk",
		},
	}).then((response) => {
		return response.data.users;
	});
};

export function useUsers() {
	return useQuery(["users"], () => {
		return fetchUsers();
	});
}

const fetchUserProfiles = async (): Promise<UserProfile[]> => {
	return axios({
		baseURL: baseURL("users"),
		method: "get",
		url: "/users",
	}).then((response) => {
		return response.data.profiles;
	});
};

export function useUserProfiles() {
	return useQuery(["userProfiles"], () => {
		return fetchUserProfiles();
	});
}

const fetchUserProfilesMap = async (): Promise<{
	[key: string]: UserProfile;
}> => {
	return axios({
		baseURL: baseURL("users"),
		method: "get",
		url: "/users",
	}).then((response) => {
		return response.data.profiles.reduce((acc: any, profile: any) => {
			acc[profile.id] = profile;
			return acc;
		}, {});
	});
};

export function useUserProfilesMap() {
	return useQuery(["userProfiles"], () => {
		return fetchUserProfilesMap();
	});
}

export function setUserProfileMetadata(userId: string, metadata: Metadata) {
	return axios({
		baseURL: baseURL("users"),
		method: "patch",
		url: `/users/${userId}/profile/metadata`,
		data: {
			metadata: [metadata],
		},
	});
}

export function deleteUserProfileMetadata(userId: string, key: string) {
	return axios({
		baseURL: baseURL("users"),
		method: "delete",
		url: `/users/${userId}/profile/metadata/${key}`,
	});
}

const fetchRoles = async (): Promise<Roles> => {
	return axios({
		baseURL: baseURL("auth"),
		method: "get",
		url: "/auth/roles",
	}).then((response) => {
		return response.data.roles;
	});
};

export function useRoles() {
	return useQuery(["roles"], () => {
		return fetchRoles();
	});
}

export function updateUserRoles(
	userId: string,
	roles: string[],
	removeRoles?: string[],
) {
	return axios({
		baseURL: baseURL("auth"),
		method: "post",
		url: `/auth/roles/grant`,
		data: {
			id: userId,
			roles,
			removeRoles,
		},
	});
}

export const deleteUser = (userId: string) => {
	return axios({
		baseURL: baseURL("auth"),
		method: "delete",
		url: `/auth/users/${userId}`,
		params: {
			userId,
		},
	});
};

export const addUser = (email: string, name: string, password: string) => {
	return axios({
		baseURL: baseURL("auth"),
		method: "post",
		url: `/auth/users`,
		data: {
			email,
			name,
			password,
			tenantId: "kentucky-eagle-15dzk",
		},
	});
};

const fetchCustomers = async (): Promise<CustomerV2[]> => {
	return axios({
		baseURL: baseURL("auth"),
		method: "get",
		url: `/auth/customers`,
	}).then((response) => {
		return response.data.customers;
	});
};

export function useCustomers() {
	return useQuery(
		["customers"],
		() => {
			return fetchCustomers();
		},
		{
			cacheTime: 3600000, // 1 hour
		},
	);
}

const fetchIsUsernameValid = async (username?: string): Promise<boolean> => {
	return axios({
		baseURL: baseURL("users"),
		method: "get",
		url: `/users/username/${username}/unique`,
	}).then((response) => {
		return response.data.unique;
	});
};

export function useIsUsernameValid(username?: string) {
	return useQuery(
		["isUsernameValid", username],
		() => {
			return fetchIsUsernameValid(username);
		},
		{
			enabled: username !== undefined && username !== "",
			cacheTime: 0,
		},
	);
}
