import { createContext, useContext } from "react";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { useAuth } from "../../auth/contexts/AuthProvider";

interface ConfigProviderInterface {
	mapboxApiKey: string;
}

export const ConfigContext = createContext({} as ConfigProviderInterface);

type ConfigProviderProps = {
	children: React.ReactNode;
};

const ConfigProvider = ({ children }: ConfigProviderProps) => {
	const fullConfig = {
		// trunk-ignore(gitleaks/generic-api-key)
		mapboxApiKey:
			"pk.eyJ1IjoiY2hhcmxlc2Vzc2llbiIsImEiOiJjbG44dXA3cHQwMThnMm5uMjA5bWNqYzM5In0.Kz-00ziyeiaNqXgM7eI4-Q",
	} as ConfigProviderInterface;

	const { userInfo } = useAuth();

	return (
		<ConfigContext.Provider value={fullConfig}>
			<LDProvider
				clientSideID="649341f7ed62511370404e53"
				context={{
					kind: "user",
					key: userInfo?.userID,
					name: userInfo?.name,
					email: userInfo?.email,
				}}
				options={{
					streaming: true,
				}}
			>
				{children}
			</LDProvider>
		</ConfigContext.Provider>
	);
};

export function useConfig() {
	return useContext(ConfigContext);
}

export default ConfigProvider;
