import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./core/components/PrivateRoute";
import RouteCorrelationPage from "./admin/pages/customer/routes/RouteCorrelation";

// Admin
const Admin = lazy(() => import("./admin/pages/Admin"));
const Overview = lazy(() => import("./admin/pages/customer/Overview"));

const RetailersPage = lazy(() => import("./admin/pages/customer/Retailers"));
const RetailerPage = lazy(() => import("./admin/pages/customer/Retailer"));

const UsersPage = lazy(() => import("./admin/pages/customer/Users"));
const ProfilePage = lazy(() => import("./admin/pages/Profile"));

// Auth
const Login = lazy(() => import("./auth/pages/Login"));

// Core
const Forbidden = lazy(() => import("./core/pages/Forbidden"));
const NotFound = lazy(() => import("./core/pages/NotFound"));
const UnderConstructions = lazy(
	() => import("./core/pages/UnderConstructions"),
);

const AppRoutes = () => {
	const privateRouteProps = {
		permissions: ["admin:write"],
	};

	return (
		<Routes>
			<Route
				path="/"
				element={
					<Navigate
						to={`${process.env.PUBLIC_URL}/customer/overview`}
						replace
					/>
				}
			/>
			<Route
				path="customer"
				element={
					<PrivateRoute>
						<Admin />
					</PrivateRoute>
				}
			>
				<Route
					path="explore"
					element={
						<PrivateRoute>
							<RouteCorrelationPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="profile"
					element={
						<PrivateRoute>
							<ProfilePage />
						</PrivateRoute>
					}
				>
					<Route
						path=":userId"
						element={
							<PrivateRoute>
								<ProfilePage />
							</PrivateRoute>
						}
					/>
				</Route>
				<Route
					path="overview"
					element={
						<PrivateRoute>
							<Overview />
						</PrivateRoute>
					}
				/>
				<Route path="accounts">
					<Route
						path=":retailerId"
						element={
							<PrivateRoute>
								<RetailerPage />
							</PrivateRoute>
						}
					/>
					<Route
						path=""
						element={
							<PrivateRoute>
								<RetailersPage />
							</PrivateRoute>
						}
					/>
				</Route>
				<Route
					path="users"
					element={
						<PrivateRoute>
							<UsersPage />
						</PrivateRoute>
					}
				/>
				<Route
					path=""
					element={
						<Navigate
							to={`${process.env.PUBLIC_URL}/customer/overview`}
							replace
						/>
					}
				/>
			</Route>
			<Route
				path="admin"
				element={
					<PrivateRoute {...privateRouteProps}>
						<Admin />
					</PrivateRoute>
				}
			>
				<Route
					path=""
					element={
						<Navigate
							to={`${process.env.PUBLIC_URL}/customer/overview`}
							replace
						/>
					}
				/>
			</Route>
			<Route path="login" element={<Login />} />
			<Route path="under-construction" element={<UnderConstructions />} />
			<Route path="403" element={<Forbidden />} />
			<Route path="404" element={<NotFound />} />
			<Route
				path="*"
				element={<Navigate to={`${process.env.PUBLIC_URL}/404`} replace />}
			/>
		</Routes>
	);
};

export default AppRoutes;
