import { Navigate, RouteProps, useNavigate } from "react-router";

import { useAuth } from "../../auth/contexts/AuthProvider";
import { useEffect } from "react";

type PrivateRouteProps = RouteProps & {
  permissions?: string[];
};

const PrivateRoute = ({ permissions, children }: PrivateRouteProps) => {
  const { hasPermission, token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(`${process.env.PUBLIC_URL}/login`);
    }
  }, [token, navigate]);

  if (!hasPermission(permissions)) {
    return <Navigate to={`${process.env.PUBLIC_URL}/403`} />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
