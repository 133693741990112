import IconButton from "@mui/material/IconButton";
import Toolbarz from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import { useSettings } from "../../core/contexts/SettingsProvider";
import { Breadcrumbs, Link } from "@mui/material";

type BreadCrumb = {
  name: string;
  link: string;
};

type ToolbarProps = {
  children?: React.ReactNode;
  breadcrumbs?: BreadCrumb[];
};

const Toolbar = ({ children, breadcrumbs }: ToolbarProps) => {
  const { toggleDrawer } = useSettings();

  const handleGoBack = () => {
    window.history.back();
  };
  return (
    <Toolbarz sx={{ px: { xs: 3, sm: 6 } }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={toggleDrawer}
        sx={{
          display: { lg: "none" },
          marginRight: 2,
        }}
      >
        <MenuIcon />
      </IconButton>
      <IconButton
        aria-label="go back"
        size="large"
        sx={{
          display: "none", // TODO enable back button with proper navigation
          // display: breadcrumbs ? { xs: "none", lg: "inherit" } : "none",
        }}
        onClick={handleGoBack}
      >
        <ArrowCircleLeftIcon />
      </IconButton>
      <Typography
        variant="h3"
        sx={{
          flexGrow: 1,
          display: "none", // TODO enable back button with proper navigation
          // display: breadcrumbs ? { xs: "none", lg: "inherit" } : "none",
        }}
      >
        Back
      </Typography>
      <Breadcrumbs aria-label="breadcrumb" sx={{ flexGrow: 8 }}>
        {breadcrumbs &&
          breadcrumbs.map((crumb) => (
            <Link
              underline="hover"
              color="inherit"
              href={crumb.link}
              key={crumb.name}
            >
              {crumb.name}
            </Link>
          ))}
      </Breadcrumbs>
      {children}
    </Toolbarz>
  );
};

export default Toolbar;
