import axios from "axios";
import { useQuery } from "react-query";
import { Graph } from "../../core/types/graph";
import { baseURL } from "../util/url";

const fetchForecast = async (d: Date): Promise<any> => {
	return axios({
		baseURL: baseURL("scenarios"),
		method: "get",
		url: `/scenarios/forecast?daysAhead=7&startDate=${d.toISOString()}`,
	}).then((response) => {
		return response.data.demand.map((d: any) => {
			return {
				date: d.deliveryWindow.startTime.split("T")[0],
				Cases: d.cases.value,
				Kegs: d.kegs.value,
				Eaches: d.eaches.value,
			};
		});
	});
};

export function useForecast(d: Date) {
	return useQuery(["forecast", d.getDate()], () => fetchForecast(d), {
		enabled: true,
	});
}

const fetchGalileo = async (simulationId: string): Promise<Graph> => {
	return axios({
		method: "get",
		url: `/orders/galileo`,
		params: {
			simulationId: simulationId,
		},
	}).then((response) => {
		return response.data.graph;
	});
};

export function useGalileo(simulationId: string) {
	return useQuery(["galileo", simulationId], () => fetchGalileo(simulationId), {
		enabled: true,
	});
}
