import "./App.css";
import AppRoutes from "./AppRoutes";
import AuthProvider from "./auth/contexts/AuthProvider";
import SettingsProvider from "./core/contexts/SettingsProvider";
import SnackbarProvider from "./core/contexts/SnackbarProvider";
import ConfigProvider from "./core/contexts/ConfigProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import axios from "axios";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
	"b8867a0dbddb752d2d670f56795167d4Tz04NjY5MSxFPTE3NDIzOTE3MjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: 0,
			cacheTime: 1000 * 60 * 60 * 1, // 1 hour
		},
	},
});

if (process.env.REACT_APP_API_SERVER) {
	console.log(`using API gateway base ${process.env.REACT_APP_API_SERVER}`);
	axios.defaults.baseURL = process.env.REACT_APP_API_SERVER;
}

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<SettingsProvider>
				<SnackbarProvider>
					<AuthProvider>
						<ConfigProvider>
							<AppRoutes />
						</ConfigProvider>
					</AuthProvider>
				</SnackbarProvider>
			</SettingsProvider>
		</QueryClientProvider>
	);
}

export default App;
