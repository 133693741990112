import { useMutation } from "react-query";
import {
	GoogleAuthProvider,
	signInWithPopup,
	signInWithEmailAndPassword,
	browserLocalPersistence,
	signOut,
} from "@firebase/auth";
import { getAuth } from "./app";

import { Credential } from "../types/credentials";

const customerMap = (email: string) => {
	const customerMap = {
		"@kyeagle.net": "kentucky-eagle-15dzk",
		"@mitchelldistributing.com": "mitchell-distrib-q7uwg",
		"@mitchellcompanies.com": "mitchell-distrib-q7uwg",
		"@mitchbev.com": "mitchell-distrib-q7uwg",
		"@greaterindustries.co": null,
		"@greater.co": null,
		"@acmebeer.com": "acme-distrib-miz4o",
		"": null,
	} as Record<string, string | null>;

	const customer = Object.keys(customerMap).find((key) => email.includes(key));

	return customerMap[customer || ""];
};

const login = async ({
	email,
	password,
}: {
	email: string;
	password: string;
}): Promise<Credential | null> => {
	const auth = await getAuth();
	await auth.setPersistence(browserLocalPersistence);

	if (email === "") {
		const provider = new GoogleAuthProvider();
		provider.addScope("https://www.googleapis.com/auth/contacts.readonly");

		return signInWithPopup(auth, provider).then(async (result) => {
			const credential = {
				token: await result.user.getIdToken(),
				user: result.user,
			} as Credential;

			return credential;
		});
	} else {
		auth.tenantId = customerMap(email);
		return signInWithEmailAndPassword(auth, email, password).then(
			async (userCredential) => {
				const credential = {
					token: await userCredential.user.getIdToken(),
					user: userCredential.user,
				} as Credential;

				return credential;
			},
		);
	}
};

const logout = async () => {
	const auth = await getAuth();

	return signOut(auth).then(() => {
		window.location.reload();
	});
};

export function useLogin() {
	const { isLoading, mutateAsync } = useMutation(login);

	return { isLoggingIn: isLoading, login: mutateAsync, logout };
}
