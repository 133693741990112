import axios from "axios";
import React, { createContext, useContext } from "react";
import { useLogin } from "../hooks/useLogin";
import { useUserInfo } from "../hooks/useUserInfo";
import { UserInfo } from "../types/userInfo";
import { useLocalStorage } from "../../core/hooks/useLocalStorage";
import { useCustomers } from "../../admin/hooks/useUserProfile";
import { CustomerV2 } from "../../admin/types/customer";

interface AuthContextInterface {
	getTenantId: () => string | undefined;
	getActiveHub: () => string | undefined;
	getCustomer: () => CustomerV2 | undefined;
	setTenant(tenantId: string, activeHub: string): Promise<void>;
	hasPermission: (permissions?: string[]) => boolean;
	isLoggingIn: boolean;
	login: (email: string, password: string) => Promise<any>;
	logout: () => Promise<any>;
	userInfo?: UserInfo;
	token?: string;
}

export const AuthContext = createContext({} as AuthContextInterface);

type AuthProviderProps = {
	children?: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
	const { isLoggingIn, login, logout } = useLogin();
	const { data: userInfo } = useUserInfo();
	const [tenantId, setTenantId] = useLocalStorage("tenantId", "");
	const [activeHub, setActiveHub] = useLocalStorage("activeHub", "");
	const { data: customers } = useCustomers();

	const getCustomer = (): CustomerV2 | undefined => {
		if (tenantId && customers) {
			return customers.find((customer) => customer.tenantId === tenantId);
		}
		return undefined;
	};

	const hasPermission = (permissions?: string[]): boolean => {
		if (
			!permissions ||
			permissions.length === 0 ||
			(permissions.length === 1 && permissions[0] === "")
		) {
			return true;
		}
		if (!userInfo) {
			return false;
		}

		const expandedPermissions = userInfo.permissions.flatMap(permissiveSet);
		if (expandedPermissions.includes("admin:write")) {
			return true;
		}
		for (const permission of permissions) {
			if (!expandedPermissions.includes(permission)) {
				return false;
			}
		}
		return true;
	};

	const permissiveSet = (permission: string): string[] => {
		const [perm, accessMode] = permission.split(":");
		if (accessMode === "write") {
			return [`${perm}:write`, `${perm}:read`];
		}

		return [perm];
	};

	const handleLogin = async (email: string, password: string) => {
		return login({ email, password }).then(() => {
			window.location.href = `${process.env.PUBLIC_URL}/customer/overview`;
		});
	};

	const handleLogout = async () => {
		await logout();
		return;
	};

	const getTenantId = (): string | undefined => {
		if (tenantId && tenantId !== "") {
			return tenantId;
		}

		if (userInfo?.token) {
			const decodedToken = JSON.parse(atob(userInfo.token.split(".")[1]));
			if (decodedToken.firebase?.tenant) {
				setTenantId(decodedToken.firebase.tenant);
				return decodedToken.firebase.tenant;
			}
			return decodedToken.tenant_id;
		}

		return undefined;
	};

	const getActiveHub = (): string | undefined => {
		if (getTenantId() !== tenantId || getTenantId() === undefined) {
			setTenantId(getTenantId() || "");
			setActiveHub("");
			return undefined;
		}

		if (activeHub && activeHub !== "") {
			return activeHub;
		}
	};

	const setTenant = (tenantId: string, activeHub: string) => {
		return new Promise<void>((resolve) => {
			setTenantId(tenantId);
			setActiveHub(activeHub);
			resolve();
		});
	};

	if (tenantId && tenantId !== "") {
		axios.defaults.headers.common["Grpc-Metadata-X-Tenant-ID"] = tenantId;
	}

	if (activeHub && activeHub !== "") {
		axios.defaults.headers.common["Grpc-Metadata-X-Active-Hub"] = activeHub;
	}

	return (
		<AuthContext.Provider
			value={{
				hasPermission,
				isLoggingIn,
				login: handleLogin,
				logout: handleLogout,
				userInfo,
				token: userInfo?.token,
				getTenantId,
				getActiveHub,
				setTenant,
				getCustomer,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export function useAuth() {
	return useContext(AuthContext);
}

export default AuthProvider;
