import { useEffect, useRef, RefObject } from "react";

type CanvasProps = {
  props: any;
  graph?: any;
  fullWidth?: boolean;
  render: (ctx: CanvasRenderingContext2D) => void;
};

const Canvas = ({ props, graph, render, fullWidth }: CanvasProps) => {
  const canvasRef = useRef<RefObject<HTMLCanvasElement> | null>(null);

  useEffect(() => {
    if (
      !canvasRef.current ||
      !(canvasRef.current instanceof HTMLCanvasElement)
    ) {
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    render(ctx);
  }, [graph, render]);

  return (
    <canvas
      ref={canvasRef}
      {...props}
      style={fullWidth ? { width: "100%" } : {}}
    />
  );
};

export default Canvas;
